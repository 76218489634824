import React from "react";
import { WirelessSurveyOut } from "../../openapi/model/wirelessSurveyOut";
import { ListGroupItem } from "reactstrap";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import { formatDate } from "../../components/utils/HighlightNullAndOldDates";

export default function WirelessSurveyListRowInner(props: {
  wirelessSurvey: WirelessSurveyOut;
}): JSX.Element {
  const { wirelessSurvey } = props;

  return (
    <ListGroupItem className={"px-0 py-1 flex-column align-items-start"}>
      <div className="d-flex flex-row w-100 justify-content-between">
        <div className="w-1/2 align-items-center">
          <CompanyNavLink to={`view/${wirelessSurvey.surveyId}`}>
            <h5 className={"mb-0"}>{wirelessSurvey.surveyName}</h5>
          </CompanyNavLink>
          <p className="text-sm mb-0 mt-1">{wirelessSurvey.surveyId}</p>
        </div>
        <div className="w-1/2 align-items-center">
          <h5 className={"mb-0"}>{formatDate(wirelessSurvey.createdAt)}</h5>
        </div>
      </div>
    </ListGroupItem>
  );
}
